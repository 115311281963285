import axios from 'axios'
import { IMainReviewer, IPage, Review } from '@Types'

const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_ADMIN_URL,
})

export default instance

export async function fetchReviewsCount(): Promise<any> {
    const { data } = await instance.get(`/reviews/count`)
    return data
}

export async function fetchReviews({
    limit = 3,
    start = 0,
}: {
    start?: number
    limit: number
}): Promise<any> {
    const { data } = await instance.get(
        `/reviews?_start=${start}&_limit=${limit}`
    )

    return data
}

export async function fetchSpeakers() {
    const { data } = await instance.get('/speakers')

    return data
}

export async function getSpeaker(slug: string, preview?: boolean) {
    const { data } = await instance.get(
        `/speakers?slug=${slug}${preview ? '&_publicationState=preview' : ''}`
    )

    return data[0]
}

export async function fetchMainReview(): Promise<IMainReviewer | null> {
    try {
        const { data } = await instance.get('/main-reviewer')
        return data
    } catch (e) {
        return null
    }
}

export async function addLike(id: string | number, userId: string) {
    const { data } = await instance.patch(`/reviews/like-review`, {
        id,
        userId,
    })

    return data
}

export async function removeLike(id: string | number, userId: string) {
    const { data } = await instance.patch(`/reviews/unlike-review`, {
        id,
        userId,
    })

    return data
}

export async function addCardLike(
    reviewId: string | number,
    userId: string,
    cardId: string
) {
    const { data } = await instance.patch(`/reviews/like-card`, {
        reviewId,
        userId,
        cardId,
    })

    return data
}

export async function removeCardLike(
    reviewId: string | number,
    userId: string,
    cardId: string
) {
    const { data } = await instance.patch(`/reviews/unlike-card`, {
        reviewId,
        userId,
        cardId,
    })

    return data
}

export async function fetchReview(
    reviewId: number,
    preview?: boolean
): Promise<Review | null> {
    try {
        const { data } = await instance.get(
            `/reviews/${reviewId}${preview ? '?_publicationState=preview' : ''}`
        )

        return data as Review
    } catch (e) {
        return null
    }
}

export async function fetchLikesCountByReviewCardID(
    reviewId: number,
    reviewCardId: string | number
): Promise<number> {
    try {
        const review = await fetchReview(reviewId)

        const reviewItem = review?.reviewCards.find(
            (reviewCard) => reviewCard.id === reviewCardId
        )

        if (reviewItem?.likes) {
            return reviewItem?.likes.length
        }

        return 0
    } catch (e) {
        return 0
    }
}

export async function fetchReviewLikesByReviewID(
    reviewId: number
): Promise<number> {
    try {
        const review = await fetchReview(reviewId)

        if (review?.likes) {
            return review?.likes.length
        }

        return 0
    } catch (e) {
        return 0
    }
}

export async function fetchPage(slug: string): Promise<IPage | null> {
    try {
        const { data: page } = await instance.get(`/pages?slug=${slug}`)

        if (!page[0].seo) {
            return null
        }

        return page[0]
    } catch (e) {
        return null
    }
}
