import { ReactNode } from 'react'
import Flickity from 'react-flickity-component'
import styles from './Slider.module.scss'

const Slider = ({
    options,
    children,
    flickityRef,
}: {
    options: any
    children: ReactNode
    flickityRef?: any
}) => {
    const handleFlickityRef = (ref: Flickity) => {
        if (flickityRef) {
            flickityRef.current = ref
        }
    }

    return (
        <Flickity
            className={styles.slider}
            elementType="div"
            options={options}
            reloadOnUpdate={true}
            flickityRef={handleFlickityRef}
        >
            {children}
        </Flickity>
    )
}

export default Slider
