import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './LazyImage.module.scss'
import { Image } from '@Types'

interface ILazyImageProps {
    src: Image
    className?: string
    alt: string
}

export const LazyImage = ({ src, className, alt }: ILazyImageProps) => {
    const [blur, setBlur] = useState(true)
    const [imageUrl, setImageUrl] = useState(
        src?.formats?.thumbnail?.url || src?.url
    )

    const loadOriginal = () => {
        const originalImage = document.createElement('img')
        originalImage.src = src?.url
        originalImage.onload = () => {
            setImageUrl(src.url)
            setBlur(false)
        }
    }

    useEffect(() => {
        if (src?.formats?.medium?.url) {
            const imgElement = document.createElement('img')
            imgElement.src = src.formats?.medium?.url || ''
            imgElement.onload = () => {
                setImageUrl((prev) => {
                    if (prev !== src.url) {
                        return imgElement.src
                    }
                    return src.url
                })
                setBlur(false)
            }
        }

        loadOriginal()
    }, [])

    if (!src?.formats) {
        return <img src={src?.url} alt={alt} className={className} />
    }

    return (
        <>
            <img
                src={imageUrl}
                className={cn(className, { [styles.blur]: blur })}
                alt={alt}
            />
        </>
    )
}

export default LazyImage
