import React from 'react'
import Head from 'next/head'

export const PageHead: React.FC<{
    entity?: any
    type: 'page' | 'post'
}> = ({ entity, type = 'post' }: any) => {
    if (!entity.seo) {
        return null
    }

    const { metaTitle, metaDescription, metaImage } = entity?.seo

    return (
        <Head>
            <title>{metaTitle}</title>
            {metaTitle && (
                <>
                    <meta
                        key={metaTitle}
                        property="title"
                        content={metaTitle}
                    />
                    <meta
                        key={metaTitle}
                        property="og:title"
                        content={metaTitle}
                    />
                </>
            )}
            {metaDescription && (
                <>
                    <meta name="description" content={metaDescription} />
                    <meta property="og:description" content={metaDescription} />
                </>
            )}
            {metaImage?.url && (
                <meta property="og:image" content={metaImage?.url} />
            )}
            {metaImage?.url && metaTitle && metaDescription && type === 'post' && (
                <>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@flowmapp" />
                    <meta name="twitter:title" content={metaTitle} />
                    <meta
                        name="twitter:description"
                        content={metaDescription}
                    />
                    <meta name="twitter:image" content={metaImage?.url} />
                </>
            )}
        </Head>
    )
}
