import styles from './SpeakerInfo.module.scss'
import cx from 'classnames'
import { Speaker, SpeakerInfo, SpeakerSize } from '@Types'
import LazyImage from '@Components/LazyImage/LazyImage'

const SpeakerInfo = ({
    speaker,
    size,
    title,
}: {
    speaker: Speaker | SpeakerInfo
    size: SpeakerSize
    title?: string
}) => {
    if (!speaker) {
        return <></>
    }

    return (
        <div className={styles.container}>
            <LazyImage
                className={cx(styles.img, styles[size])}
                src={speaker?.image}
                alt={speaker?.name}
            />
            <div className={styles.info}>
                <h3 className={styles.name}>{speaker.name}</h3>
                <h4 className={styles.position}>{speaker.position}</h4>
                <h4 className={styles.reviewTitle}>{title}</h4>
            </div>
        </div>
    )
}

export default SpeakerInfo
