export const MONTH_NAMES = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
]

export const formatDate = (
    date?: string,
    format: 'short' | 'medium' = 'medium'
) => {
    if (!date) {
        return
    }

    const newDate = new Date(date)
    const day = newDate.getDate()
    const month = MONTH_NAMES[newDate.getMonth()]
    const year =
        format === 'short'
            ? String(newDate.getFullYear()).slice(2)
            : newDate.getFullYear()

    return `${day} ${month} ${year}`
}
